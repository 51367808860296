.contact-section-container {
  border-top: solid 1px #bcbcbc;
}

.contact-container {
  border-bottom: solid 1px #bcbcbc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-128) 0;
  @media (max-width: $layout-breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-64);
  }
}

.contact-e-t-container {
  display: flex;
  flex-direction: column;
}

.contact-email-container {
  position: relative;
  display: inline-block;
  padding: var(--spacing-16) 0;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    .contact-cta-line {
      transform: translateX(100%);
    }
  }
}

.contact-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  bottom: 0;
  border-bottom: 4px solid;
  background-color: var(--text-primary);
  width: 100%;
}
