.footer-container {
  padding-top: var(--spacing-128);
}

.footer-links-container {
  display: flex;
  gap: var(--spacing-128);
  margin-bottom: var(--spacing-256);
}

.footer-sitemap-links,
.footer-socials-links {
  li {
    padding-bottom: var(--spacing-16);
    a {
      text-decoration: none;
      color: var(--text-primary);
      opacity: 0.4;
      -webkit-transition: 0.4s cubic-bezier(0.78, 0.01, 0.21, 1);
      transition: 0.4s cubic-bezier(0.78, 0.01, 0.21, 1);
      &:hover {
        opacity: 1;
        -webkit-transition: 0.4s cubic-bezier(0.78, 0.01, 0.21, 1);
        transition: 0.4s cubic-bezier(0.78, 0.01, 0.21, 1);
      }
    }
  }
}

.footer-sitemap-heading,
.footer-socials-heading {
  padding-bottom: var(--spacing-32);
}

.footer-bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: $layout-breakpoint-mobile) {
    flex-direction: column;
    gap: var(--spacing-16);
    align-items: center;
    margin-bottom: var(--spacing-32);
  }
  h4 {
    font-size: 14px;
  }
}

.footer-logo {
  font-size: var(--spacing-256);
  font-family: "Lexend", sans-serif;
  letter-spacing: -12px;
  a {
    text-decoration: none;
    color: var(--text-primary);
  }

  @media (max-width: $layout-breakpoint-tablet) {
    font-size: var(--spacing-128);
    letter-spacing: -6px;
  }
}

.footer-scroll-btn,
.footer-scroll-btn-mob {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--text-primary);
}

.footer-scroll-btn {
  @media (max-width: $layout-breakpoint-mobile) {
    display: none;
  }
}

.footer-scroll-btn-mob {
  @media (min-width: $layout-breakpoint-mobile) {
    display: none;
  }
}
