.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--text-primary);
  transform-origin: bottom;
}

.slide-fill,
.slide-fill-2,
.slide-fill-3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--text-primary);
  opacity: 0.5;
}

.slide-fill-2 {
  opacity: 0.5;
  background: var(--text-primary);
}

.slide-fill-3 {
  opacity: 0.5;
  background: var(--text-primary);
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--text-primary);
  transform-origin: top;
  opacity: 1;
}
