//MOBILE & TABLET

h1 {
  font-size: var(--fs-72);
  font-family: var(--ff-heading);
  line-height: 0.8;
  letter-spacing: -2px;
  text-transform: uppercase;
  font-weight: 900;
}

h2 {
  font-size: var(--fs-36);
  font-family: var(--ff-para);
  line-height: 0.8;
  letter-spacing: -1px;
  font-weight: 500;
}

h3,
h4 {
  font-size: var(--fs-18);
  font-family: var(--ff-para);
  line-height: 1.2;
  // letter-spacing: var(--cs--1-1);
  font-weight: 400;
}

p {
  font-size: var(--fs-12);
  font-family: var(--ff-para);
  line-height: 1.5;
  letter-spacing: var(--cs--1-2);
}

//DESKTOP
@media (min-width: $layout-breakpoint-desktop) {
  h1 {
    font-size: var(--fs-144);
    font-family: var(--ff-heading);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 900;
  }

  h2 {
    font-size: var(--fs-72);
    font-family: var(--ff-para);
    line-height: 105%;
    letter-spacing: 95%;
    font-weight: 400;
  }

  h3 {
    font-size: var(--fs-36);
    font-family: var(--ff-para);
    line-height: 1;
    font-weight: 400;
    letter-spacing: -1px;
  }

  h4 {
    font-size: var(--fs-18);
    font-family: var(--ff-para);
    line-height: 1.2;
    // letter-spacing: var(--cs--1-1);
    font-weight: 500;
  }

  p {
    font-size: var(--fs-12);
    font-family: var(--ff-para);
    line-height: 1.5;
    // letter-spacing: var(--cs--1-2);
  }
}
