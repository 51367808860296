.hero-section {
  width: 100%;
  padding-top: var(--spacing-128);
}

.hero-splash-container {
  width: 100%;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  span {
    margin-left: -10px;
    font-family: var(--ff-heading);
    font-size: calc(var(--spacing-256) * 1.75);
    @media (max-width: $layout-breakpoint-tablet) {
      font-size: var(--spacing-256);
      margin-left: -5px;
    }
  }
}

.hero-blurb-video-container {
  position: relative;
  height: 100vh;
  display: flex;
  gap: var(--spacing-256);
  justify-content: space-between;
  margin: var(--spacing-64) 0 var(--spacing-256) 0;
  @media (hover: none) {
    flex-direction: column;
    gap: var(--spacing-64);
    justify-content: flex-start;
    height: auto;
  }
  video {
    border-radius: var(--spacing-16);
    position: absolute;
    top: 0;
    right: 0;
    @media (hover: none) {
      position: relative;
    }
  }
}

.hero-video {
  aspect-ratio: 16 / 9;
  width: 100%;
  background-color: black;
}

.hero-blurb-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-64);
}

.blurb-line-container {
  overflow: hidden;
  padding: 4px;
  @media (max-width: $layout-breakpoint-desktop) {
    padding: 0;
  }
}

.hero-cta {
  font-size: var(--fs-18);
  font-weight: 500;
}

.blurb-cover {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--background);
}
.hero-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  border-bottom: 2px solid;
  background-color: var(--text-primary);
  width: 100%;
}

.hero-blurb-cta-container {
  position: relative;
  display: inline-block;
  padding: var(--spacing-16) 0;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  &:hover {
    .hero-cta-line {
      transform: translateX(100%);
    }
  }
}
