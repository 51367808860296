:root {
  --background: white;
  --text-primary: black;
  --secondary: teal;
}
[data-theme="dark"] {
  --background: black;
  --text-primary: white;
  --secondary: teal;
}

[data-menuopen="true"] {
  --nav-menu-color: var(--background);
}

[data-menuopen="false"] {
  --nav-menu-color: white;
}
