.projects-section-container {
  margin-bottom: var(--spacing-256);
}

.projects-header-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--spacing-64);
}

.projects-title-container {
  display: flex;
  overflow: hidden;
  padding-right: var(--spacing-16);
}

.projects-img-section {
  position: relative;
  display: flex;
  gap: var(--spacing-32);
  width: 100%;
  margin-bottom: var(--spacing-256);
  img {
    width: 100%;
    border-radius: var(--spacing-16);
    margin-bottom: var(--spacing-16);
  }
  @media (max-width: 650px) {
    flex-direction: column;
    gap: var(--spacing-128);
  }
}

.projects-img-stack {
  display: grid;
}

.projects-img-stack > * {
  grid-area: 1/1;
}

.projects-img-variation {
  background-color: white;
}

.projects-video {
  align-self: center;
  justify-self: center;
}

.projects-cta-section {
  position: relative;
  padding: var(--spacing-16) 0;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-left: auto;
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    .projects-cta-line {
      transform: translateX(100%);
    }
    img {
      transform: translateX(15%);
    }
  }
  img {
    width: var(--spacing-64);
    -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
    transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  }
  @media (max-width: 650px) {
    margin-left: 0;
    img {
      width: calc(var(--spacing-32) * 1.5);
    }
  }
}

.projects-cta-content {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-32);
}

.projects-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  bottom: 0;
  border-bottom: 2px solid;
  background-color: var(--text-primary);
  width: 100%;
  width: 100%;
}

@media (max-width: 650px) {
  .projects-blurb-container,
  .projects-subtitle-container {
    display: none;
  }
}
