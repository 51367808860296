.banner-section {
  width: 100%;
  padding-top: var(--spacing-256);
  padding-bottom: var(--spacing-256);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-64);
}

.pricing-banner {
  display: grid;
  grid-template-areas: "top vid" "bottom bottom";
  @media (max-width: $layout-breakpoint-desktop) {
    grid-template-areas: "vid" "top" "bottom";
    h1 {
      line-height: 1;
    }
  }
}

.banner-video-container {
  display: inline-block;
  position: relative;
  grid-area: vid;
  justify-self: start;
  align-self: center;
  @media (max-width: $layout-breakpoint-tablet) {
    margin-bottom: var(--spacing-32);
  }
}

.banner-video {
  width: calc(var(--spacing-256) * 1.2);
  border-radius: 8px;
  @media (min-width: $layout-breakpoint-tablet) and (max-width: $layout-breakpoint-desktop) {
    width: calc(var(--spacing-256) * 0.8);
  }
}

.banner-blurb-para {
  width: 50%;
  margin-top: var(--spacing-32);
  h4 {
    opacity: 0.5;
  }
  @media (max-width: $layout-breakpoint-tablet) {
    width: 100%;
  }
}
.banner-title-top {
  grid-area: top;
}

.banner-title-bottom {
  grid-area: bottom;
}
