.services-section-container {
  margin-bottom: var(--spacing-128);
}

.services-header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--spacing-128);
}

.services-blurb {
  width: 50%;
}

.services-main-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-64);
  align-items: flex-start;
  margin-left: var(--spacing-128);
  padding-bottom: var(--spacing-128);
  @media (max-width: 650px) {
    margin-left: 0;
    gap: var(--spacing-128);
  }
}

.service-blurb-container {
  width: 50%;
  opacity: 0;
  transform: translateX(100%);
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  @media (max-width: $layout-breakpoint-desktop-lrg) {
    width: 40%;
  }
  @media (max-width: 650px) {
    opacity: 1;
    transform: translateX(0);
    width: 100%;
  }
}

.service-img-container {
  overflow: hidden;
  position: relative;
  img {
    transform: translateY(105%);
    -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
    transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  }
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
}

.services-cta {
  position: relative;
  padding: var(--spacing-16) 0;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    .services-cta-line {
      transform: translateX(100%);
    }
  }
}

.services-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  border-bottom: 2px solid;
  background-color: var(--text-primary);
  width: 100%;
}

.service-container {
  display: flex;
  gap: var(--spacing-32);
  align-items: center;
  cursor: pointer;
  img {
    width: var(--spacing-256);
  }
  h1 {
    opacity: 0.25;
  }
  &:hover {
    h1 {
      opacity: 1;
      -webkit-transition: 1s cubic-bezier(0.19, 0.01, 0.21, 1);
      transition: 1s cubic-bezier(0.19, 0.01, 0.21, 1);
    }
    .service-blurb-container {
      opacity: 1;
      transform: translateX(0);
    }
    .service-img-container {
      img {
        transform: translateY(0);
      }
    }
  }
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  .service-title-mob {
    display: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-bottom: var(--spacing-128);
    border-bottom: 1px solid lightgray;
    h1 {
      display: none;
    }
    .service-title-mob {
      opacity: 1;
      letter-spacing: 1px;
      display: block;
      padding-bottom: var(--spacing-32);
    }
    img {
      width: 100%;
      transform: translateY(0);
      align-items: left;
    }
  }
}

.service-one[data-serviceOne="true"],
.service-two[data-serviceTwo="true"],
.service-three[data-serviceThree="true"] {
  h1 {
    opacity: 1;
    -webkit-transition: 0.6s cubic-bezier(0.19, 0.01, 0.21, 1);
    transition: 0.6s cubic-bezier(0.19, 0.01, 0.21, 1);
  }
  .service-blurb-container {
    opacity: 1;
    transform: translateX(0);
  }
  .service-img-container {
    img {
      transform: translateY(0);
    }
  }
  -webkit-transition: 0.6s cubic-bezier(0.19, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.19, 0.01, 0.21, 1);
}

@media (max-width: 650px) {
  .services-blurb,
  .services-cta {
    display: none;
  }
}

.service-three {
  border-bottom: none;
}

.mobile-cta {
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
}
