.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
  color: white;
  transition: 0.3s;
  display: block;
  mix-blend-mode: difference;
}

.header[data-menuopen="true"] {
  mix-blend-mode: normal;
}

.nav-container {
  display: flex;
  align-items: center;
  height: var(--spacing-128);
  gap: var(--spacing-32);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);

  @media (max-width: $layout-breakpoint-tablet) {
    justify-content: space-between;
  }
}

.logo-container {
  margin-right: var(--spacing-128);
  a {
    text-decoration: none;
    color: white;
  }
  span {
    font-family: "Lexend", sans-serif;
    font-size: var(--spacing-32);
    letter-spacing: -1px;
  }
  z-index: 1000;
}

.nav-central-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--spacing-128);
  @media (max-width: $layout-breakpoint-tablet) {
    display: none;
  }
}

.nav-links {
  display: flex;
  gap: 4px;
  a {
    text-decoration: none;
    color: white;
  }
}

.lightmode-switch-container {
  min-width: calc(var(--spacing-64) + 4px);
  margin-right: 0;

  @media (max-width: $layout-breakpoint-tablet) {
    display: none;
  }
}

.switch {
  width: calc(var(--spacing-32) + 4px);
  height: calc(var(--spacing-32) + 4px);
  background-color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--spacing-64);
  cursor: pointer;
  padding: 2px;
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
    width: calc(var(--spacing-64) + 4px);
  }
}

.switch[data-ison="true"] {
  justify-content: flex-end;
  width: calc(var(--spacing-32) + 4px);
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    width: calc(var(--spacing-64) + 4px);
  }
}

.handle {
  width: var(--spacing-32);
  height: var(--spacing-32);
  background-color: white;
  border-radius: 40px;
}

.menu-btn-container {
  button {
    background: none;
    border: none;
    padding: 0;
  }
  display: none;
  @media (max-width: $layout-breakpoint-tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  z-index: 1000;
}

.menu-btn-text-container {
  overflow: hidden;
  height: 18px;
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu-btn-text {
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  h4 {
    color: var(--nav-menu-color);
  }
}

.drop-down-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--spacing-256) 0 var(--spacing-32) 0;
  color: var(--background);
  background: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  mix-blend-mode: normal;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.dropdown-menu-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.sitemap-links-container {
  margin-bottom: var(--spacing-32);
}

.sitemap-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-32);
  li {
    position: relative;
    justify-self: flex-start;
    a {
      text-decoration: none;
    }
  }
}

.socials-links {
  display: flex;
  gap: var(--spacing-16);
}

.links-cover {
  position: absolute;
  width: 105%;
  height: 115%;
  background-color: var(--text-primary);
  top: -5px;
}
