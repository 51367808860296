@import url("https://api.fontshare.com/v2/css?f[]=switzer@600,700,400,300,500&display=swap"),
  url("https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@700&display=swap");

:root {
  //font-size
  --fs-144: 9rem;
  --fs-72: 4.5rem;
  --fs-36: 2.25rem;
  --fs-18: 1.125rem;
  --fs-12: 0.75rem;

  //character spacing
  --cs--0-6: 0.6px;
  --cs--0-8: 0.8px;
  --cs--1-1: 1.1px;
  --cs--1-2: 1.2px;
  --cs--1-4: 1.4px;
  --cs--2: 2px;
  --cs--2-4: 2.4px;

  //font family
  --ff-heading: "Anton", sans-serif;
  --ff-para: "Switzer", serif;
}
