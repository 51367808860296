.testimonials-section-container {
  padding-top: var(--spacing-128);
}

.testimonials-header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--spacing-128);
  @media (max-width: 650px) {
    flex-direction: column;
    gap: var(--spacing-16);
  }
}

.testimonials-heading {
  h1 {
    line-height: 1;
  }
}

.testimonials-cta {
  position: relative;
  padding: var(--spacing-16) 0;
  align-self: baseline;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  -webkit-transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 0.6s cubic-bezier(0.78, 0.01, 0.21, 1);
  &:hover {
    .testimonials-cta-line {
      transform: translateX(100%);
    }
  }
}

.testimonials-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  border-bottom: 2px solid;
  background-color: var(--text-primary);
  width: 100%;
}

.testimonial-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-16);
  margin-bottom: var(--spacing-256);
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    gap: var(--spacing-32);
  }
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
  padding: var(--spacing-64);
  border-radius: var(--spacing-16);
  border: solid 1px #bcbcbc;
  @media (max-width: 650px) {
    padding: var(--spacing-32);
    align-items: center;
    h3 {
      text-align: center;
    }
  }
}

.testimonial-card-logo {
  img {
    width: var(--spacing-128);
  }
}

.testimonial-card-para {
  padding-bottom: var(--spacing-32);
}
