.about-container {
  display: flex;
  margin-bottom: var(--spacing-256);
  p {
    font-size: var(--fs-18);
    font-family: var(--ff-para);
    line-height: 1.2;
    font-weight: 500;
    opacity: 0.5;
  }
}

.arrow-container {
  padding: 20px 20vw 0 0;
  @media (max-width: $layout-breakpoint-tablet) {
    display: none;
  }

  img {
    width: var(--spacing-64);
  }
}

.about-content-container {
  display: flex;
  flex-direction: column;
}

.about-heading-container {
  margin-bottom: var(--spacing-128);
}

.about-heading-line {
  position: relative;
  overflow: hidden;
  padding: 4px;
}

.about-heading-cover {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--background);
}

.about-blurb-container {
  display: flex;
  gap: var(--spacing-256);
  padding-right: var(--spacing-256);
  @media (max-width: $layout-breakpoint-desktop) {
    gap: var(--spacing-128);
    padding-right: var(--spacing-128);
  }
  @media (max-width: $layout-breakpoint-tablet) {
    flex-direction: column;
    padding-right: 0;
  }
}

.about-blurb-content-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

.about-blurb-cta {
  margin-top: var(--spacing-32);
  position: relative;
  display: inline-block;
  padding: var(--spacing-16) 0;
  overflow-x: hidden;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  &:hover {
    .about-cta-line {
      transform: translateX(100%);
    }
  }
  p {
    opacity: 1;
  }
}

.about-cta-line {
  content: "";
  position: absolute;
  transition: all 0.5s;
  right: 0;
  border-bottom: 2px solid;
  background-color: var(--text-primary);
  width: 100%;
}
